exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-learn-mouse-sticky-effect-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/learn/mouse-sticky-effect.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-learn-mouse-sticky-effect-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-dummy-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/dummy.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-dummy-mdx" */),
  "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-process-of-becoming-expert-mdx": () => import("./../../../src/pages/blog/learn/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/process-of-becoming-expert.mdx" /* webpackChunkName: "component---src-pages-blog-learn-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-process-of-becoming-expert-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-learn-mouse-sticky-effect-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/learn/mouse-sticky-effect.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-learn-mouse-sticky-effect-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-dummy-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/dummy.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-dummy-mdx" */),
  "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-process-of-becoming-expert-mdx": () => import("./../../../src/pages/blog/life/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/src/content/life/process-of-becoming-expert.mdx" /* webpackChunkName: "component---src-pages-blog-life-mdx-frontmatter-slug-tsx-content-file-path-src-content-life-process-of-becoming-expert-mdx" */),
  "component---src-pages-blog-tags-index-tsx": () => import("./../../../src/pages/blog/tags/index.tsx" /* webpackChunkName: "component---src-pages-blog-tags-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-index-tsx": () => import("./../../../src/pages/info/index.tsx" /* webpackChunkName: "component---src-pages-info-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-templates-learn-page-template-tsx": () => import("./../../../src/templates/LearnPageTemplate.tsx" /* webpackChunkName: "component---src-templates-learn-page-template-tsx" */),
  "component---src-templates-life-page-template-tsx": () => import("./../../../src/templates/LifePageTemplate.tsx" /* webpackChunkName: "component---src-templates-life-page-template-tsx" */)
}

